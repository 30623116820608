// import { reject, resolve } from "core-js/features/promise";
// import { resolve } from "core-js/features/promise";
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    accessToken: null,
    refreshToken: null,
  },
  mutations: {
    updateStorage(state, { access, refresh }) {
      state.accessToken = access;
      state.refreshToken = refresh;
    },
    destroyToken(state) {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.accessToken != null;
    },
  },
  actions: {
    userLogin(context, usercredentials) {
      var baseURL;
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://127.0.0.1:8000/api/";
      } else {
        baseURL = "https://bargou.de/api/";
      }
      return axios
        .post(baseURL.concat("token/"), {
          username: usercredentials.username,
          password: usercredentials.password,
        })
        .then((response) => {
          context.commit("updateStorage", {
            access: response.data.access,
            refresh: response.data.refresh,
          });
        })
        .catch(() => {
          throw "The input was incorrect. Please try again.";
        });
    },
    userLogout(context) {
      if (context.getters.isAuthenticated) {
        context.commit("destroyToken");
      }
    },
  },
});
