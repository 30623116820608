<template>
  <div>
    <v-app-bar app color="accent" height="90%;">
      <router-link to="/#app" id="logo">
        <v-img
          class="mx-sm-2"
          src="../assets/logo.png"
          max-height="40"
          max-width="40"
          contain
        ></v-img>
      </router-link>
      <div
        class="text-overline text--disabled mx-4"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        Experiment Programming
      </div>
      <v-spacer></v-spacer>
      <v-btn icon href="mailto:marlene@bargou.de">
        <v-icon color="tertiary">mdi-email</v-icon>
      </v-btn>
      <!-- <IconLink link="/information" icon="mdi-information-outline"></IconLink> -->
      <!--<IconLink link="/#contact-form" icon="mdi-email"></IconLink>
      <IconLink
        v-if="!isAuthenticated"
        link="/login"
        icon="mdi-login-variant"
      ></IconLink>
      <IconLink v-else link="/logout" icon="mdi-logout-variant"></IconLink>-->
    </v-app-bar>
  </div>
</template>

<script>
// import IconLink from "./IconLink.vue";

export default {
  name: "Navbar",
  components: {
    // IconLink,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#logo {
  margin-left: 1rem;
}

#contact {
  margin-right: 1rem;
}

a {
  text-decoration: none;
}
</style>
