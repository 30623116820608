<template>
  <v-carousel
    cycle
    height="80vh"
    hide-delimiter-background
    :show-arrows="false"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <v-sheet color="primary" height="80vh">
        <v-container class="fill-height px-8 px-sm-0">
          <v-row justify="center">
            <div class="slide-header">
              {{ slide }}
              <div class="slide-description">
                {{ description[i] }}
              </div>
            </div>
          </v-row>
        </v-container>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      slides: [
        "Individual Solutions",
        "Reliable, Simple & Quick",
        "Web Applications",
        "IT Support",
      ],
      description: [
        "for behavioral experiments, strategy games & multiplayer games",
        "all-in-one software for your data collection",
        "compatible with any device & access to participants anywhere",
        "from development to production",
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-header {
  font-size: 3.5rem;
  font-family: Georgia !important;
  color: rgb(41, 41, 41);
  text-align: center;
}

.slide-description {
  font-size: 1rem;
  font-family: Georgia !important;
  color: #dfdfdf;
  text-align: center;
}
</style>
