<template>
  <v-container class="timeline-container">
    <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item
        v-for="(item, i) in items"
        :key="i"
        color="primary"
        :icon="item.icon"
        fill-dot
        class="my-10 pr-10 pr-md-0"
      >
        <h2 class="font-weight-light mb-4">{{ i + 1 }}. {{ item.title }}</h2>
        <div style="text-align: justify;">{{ item.text }}</div>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  name: "Timeline",
  props: {},
  data: () => ({
    items: [
      {
        title: "Contact",
        icon: "mdi-email",
        text:
          "Get in touch via email attaching your project's idea. After exchanging all requirements, we can set up a procedure for your project.",
      },
      {
        title: "Development",
        icon: "mdi-code-tags",
        text:
          "Then your project will be developed. The time and effort this requires strongly depends on your project's complexity. You can revise the first implementation suggestion and submit the changes that you would like to see.",
      },
      {
        title: "Production",
        icon: "mdi-database",
        text:
          "If the results satisfy your expectations, the project will go into production. You will be provided with platforms to configure and monitor your sessions in real-time. Of course, I am available for further technical support.",
      },
    ],
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.timeline-container {
  max-width: 700px;
}
</style>
