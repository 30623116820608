import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import axios from "axios";

// NODE_ENV is saved in .env file in project's root
var axiosConfig;
if (process.env.NODE_ENV === "development") {
  console.log("DEVELOPMENT");
  axiosConfig = { baseURL: "http://127.0.0.1:8000/api" };
} else {
  console.log("PRODUCTION");
  axiosConfig = { baseURL: "https://bargou.de/api" };
}

Vue.prototype.$axios = axios.create(axiosConfig);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!store.getters.isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
