<template>
  <v-container class="px-sm-5 mt-sm-5" fluid>
    <v-sheet class="pa-7 pa-sm-10" color="white" elevation="3" align="center">
      <v-row class="clients-header" justify="center">
        Trusted By
      </v-row>
      <v-row no-gutters>
        <v-col
          class="clients-name my-5 my-sm-10"
          v-for="(client, i) in clients"
          :key="i"
          cols="12"
          sm="2"
        >
          <div>
            <div class="client-icon">
              <v-icon color="blue-grey">mdi-map-marker</v-icon>
            </div>
            {{ client }}
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Clients",
  data() {
    return {
      clients: [
        "Technische Universität München",
        "Technische Hochschule Ingolstadt",
        "Hochschule für Politik München",
        "Bayerische Akademie der Wissenschaften",
        "Technische Universität Bergakademie Freiberg",
        "Ludwig-Maximilian-Universität München",
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clients-header {
  color: rgb(143, 143, 143);
}

.clients-name {
  color: rgb(41, 41, 41);
}

.client-icon {
  margin-bottom: 1rem;
}
</style>
