<template>
  <v-footer color="white">
    <v-row no-gutters>
      <v-col>
        <!-- <router-link to="/privacy-statement"
          ><small>Privacy Statment</small>
        </router-link> -->
      </v-col>
      <v-col class="text-center">
        <small>&copy; {{ new Date().getFullYear() }} Bargou</small>
      </v-col>
      <v-col class="text-right">
        <router-link to="/imprint"><small>Imprint</small> </router-link>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
