<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<style>
a {
  text-decoration: none;
  margin-right: 0.5rem;
}
#app {
  font-family: Georgia !important;
  color: rgb(41, 41, 41);
}
.v-application {
  font-family: Georgia !important;
  color: rgb(41, 41, 41);
}
.v-carousel__controls {
  align-items: start !important;
}
</style>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import store from "./store";
import router from "./router";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  props: {},
  created() {
    // set page title
    document.title = "Bargou";

    // get token
    this.$axios.interceptors.response.use(undefined, function(err) {
      if (err.toJSON().message.includes("401")) {
        console.log("Your Token expired");
        store
          .dispatch("userLogout")
          .then(() => router.push({ name: "Login" }))
          .catch((error) => console.log(error));
        console.log(err);
      }
    });
  },
};
</script>
