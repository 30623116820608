import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blueGrey, // #E53935
        secondary: colors.blueGrey.lighten3, // #FFCDD2
        tertiary: colors.grey.darken3,
        accent: colors.blueGrey.lighten5, // #3F51B5
        alert: "#E57373",
      },
    },
  },
});
