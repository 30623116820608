<template>
  <div>
    <Carousel />
    <Clients />
    <Timeline />
    <!--<ContactForm />-->
  </div>
</template>

<style></style>

<script>
import Carousel from "../components/Carousel.vue";
import Clients from "../components/Clients.vue";
import Timeline from "../components/Timeline.vue";
// import ContactForm from "../components/ContactForm.vue";

export default {
  name: "Home",
  components: {
    Carousel,
    Clients,
    Timeline,
    // ContactForm,
  },
};
</script>
